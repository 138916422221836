import { template as template_3a2b6ece3a454e739e71a7577abb05bb } from "@ember/template-compiler";
const FKControlMenuContainer = template_3a2b6ece3a454e739e71a7577abb05bb(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
