import { template as template_aad6695a744648a386360fabbed75ab0 } from "@ember/template-compiler";
const FKText = template_aad6695a744648a386360fabbed75ab0(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
