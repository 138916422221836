import { template as template_ec82f397af714c38bc8fdd88e64c21a5 } from "@ember/template-compiler";
const FKLabel = template_ec82f397af714c38bc8fdd88e64c21a5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
