import { template as template_6a4298e860d44795ba785ab25a33315f } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_6a4298e860d44795ba785ab25a33315f(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
