import { template as template_8dd44f98ab6344668660434dc1db4fa6 } from "@ember/template-compiler";
const WelcomeHeader = template_8dd44f98ab6344668660434dc1db4fa6(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
